import { Options } from "vue-class-component";
import { baseComponents } from "../../common/form/ComponentsConfigObject";
import FormRender from "./FormRender/FormRender.vue";
import FormDesignRender from "./FormDesignRender/FormDesignRender.vue";
import FormComponentConfig from "./FormComponentConfig/FormComponentConfig.vue";
import BaseFunction from "@/mixins/BaseFunction";

@Options({
    name: "flowable-setting-form-setting",
    components: {
        FormComponentConfig,
        FormDesignRender,
        FormRender,
    },
})
export default class FormSetting extends BaseFunction {
    private formData: any = {};
    private libSelect = 0;
    private viewFormVisible = false;
    private isStart = false;
    private showMobile = true;
    //private baseComponents: any;
    private select: any = null;
    private drag = false;

    get baseComponents() {
        return baseComponents;
    }

    get forms() {
        return this.setup.formItems;
    }

    get selectFormItem() {
        return this.$store.getters["flowable/selectFormItem"];
    }

    set selectFormItem(val) {
        this.$store.dispatch("flowable/setSelectFormItem", val).then();
    }

    get nodeMap() {
        return this.$store.getters["flowable/nodeMap"];
    }

    get setup(): any {
        return this.$store.getters["flowable/design"];
    }

    set setup(val: any) {
        this.$store.dispatch("flowable/setDesign", val).then();
    }

    // public copy(node: any, index: number) {
    //     this.form.splice(index + 1, 0, Object.assign({}, node));
    // }

    public getId() {
        return "field" + (Math.floor(Math.random() * (99999 - 10000)) + 10000).toString() + new Date().getTime().toString().substring(5);
    }

    public del(index: number) {
        this.$vueAlert({
            title: "提示",
            message: "删除组件将会连带删除包含该组件的条件以及相关设置，是否继续？",
            closeButtonName: "取消",
            submitButtonName: "确定",
        }).then(() => {
            if (this.forms[index].name === "SpanLayout") {
                //删除的是分栏则遍历删除分栏内所有子组件
                this.forms[index].props.items.forEach((item: any) => {
                    this.removeFormItemAbout(item).then();
                });
                this.forms[index].props.items.length = 0;
            } else {
                this.removeFormItemAbout(this.forms[index]).then();
            }
            this.forms.splice(index, 1);
        });
    }

    public async removeFormItemAbout(item: any) {
        this.nodeMap.forEach((node: any) => {
            //搜寻条件，进行移除
            if (node.type === "CONDITION") {
                node.props.groups.forEach((group: any) => {
                    const i = group.cids.remove(item.id);
                    if (i > -1) {
                        //从子条件移除
                        group.conditions.splice(i, 1);
                    }
                });
            }
            //搜寻权限，进行移除
            if (node.type === "ROOT" || node.type === "APPROVAL" || node.type === "CC") {
                node.props.formPerms.removeByKey("id", item.id);
                if (node.props.formUser === item.id) {
                    node.props.formUser = "";
                }
            }
        });
    }

    public clone(obj: any) {
        obj.id = this.getId();
        return JSON.parse(JSON.stringify(obj));
    }

    public viewForms() {
        this.viewFormVisible = true;
    }

    public selectItem(cp: any) {
        this.selectFormItem = cp;
    }

    public getSelectedClass(cp: any) {
        return this.selectFormItem && this.selectFormItem.id === cp.id ? "border-left: 4px solid #409eff" : "";
    }

    public validateItem(err: any, titleSet: any, item: any) {
        if (titleSet.has(item.title) && item.name !== "SpanLayout") {
            err.push(`表单 ${item.title} 名称重复`);
        }
        titleSet.add(item.title);
        if (item.name === "SelectInput" || item.name === "MultipleSelect") {
            if (item.props.options.length === 0) {
                err.push(`${item.title} 未设置选项`);
            }
        } else if (item.name === "TableList") {
            if (item.props.columns.length === 0) {
                err.push(`明细表 ${item.title} 内未添加组件`);
            }
        } else if (item.name === "SpanLayout") {
            if (item.props.items.length === 0) {
                err.push("分栏内未添加组件");
            } else {
                item.props.items.forEach((sub: any) => this.validateItem(err, titleSet, sub));
            }
        }
    }

    public validate() {
        const err: any = [];
        if (this.forms.length > 0) {
            const titleSet = new Set();
            this.forms.forEach((item: any) => {
                //主要校验表格及分栏/选择器/表单名称/是否设置
                this.validateItem(err, titleSet, item);
            });
        } else {
            err.push("表单为空，请添加组件");
        }
        return err;
    }
}
