import { defineAsyncComponent } from "vue";
const TextInput = defineAsyncComponent(() => import("./config/TextInputConfig.vue"));

const NumberInput = defineAsyncComponent(() => import("./config/NumberInputConfig.vue"));
const AmountInput = defineAsyncComponent(() => import("./config/AmountInputConfig.vue"));
const TextareaInput = defineAsyncComponent(() => import("./config/TextareaInputConfig.vue"));
const SelectInput = defineAsyncComponent(() => import("./config/SelectInputConfig.vue"));
const MultipleSelect = defineAsyncComponent(() => import("./config/MultipleSelectConfig.vue"));
const DateTime = defineAsyncComponent(() => import("./config/DateTimeConfig.vue"));
const DateTimeRange = defineAsyncComponent(() => import("./config/DateTimeRangeConfig.vue"));

const Description = defineAsyncComponent(() => import("./config/DescriptionConfig.vue"));
const ImageUpload = defineAsyncComponent(() => import("./config/ImageUploadConfig.vue"));
const FileUpload = defineAsyncComponent(() => import("./config/FileUploadConfig.vue"));
const Location = defineAsyncComponent(() => import("./config/LocationConfig.vue"));
const MoneyInput = defineAsyncComponent(() => import("./config/MoneyInputConfig.vue"));
const OrgPicker = defineAsyncComponent(() => import("./config/OrgPickerConfig.vue"));
const DeptPicker = defineAsyncComponent(() => import("./config/DeptPickerConfig.vue"));
const PostPicker = defineAsyncComponent(() => import("./config/PostPickerConfig.vue"));
const UserPicker = defineAsyncComponent(() => import("./config/UserPickerConfig.vue"));
//const SignPanel = defineAsyncComponent(() => import("./config/SignPannelConfig.vue"));

//const SpanLayout = defineAsyncComponent(() => import("./config/SpanLayoutConfig.vue"));
const TableList = defineAsyncComponent(() => import("./config/TableListConfig.vue"));

export default {
    //基础组件
    TextInput,
    NumberInput,
    AmountInput,
    TextareaInput,
    SelectInput,
    MultipleSelect,
    DateTime,
    DateTimeRange,
    OrgPicker,
    DeptPicker,
    PostPicker,
    UserPicker,
    //高级组件
    Description,
    FileUpload,
    ImageUpload,
    MoneyInput,
    Location,
    //SignPanel,
    //SpanLayout,
    TableList,
};
