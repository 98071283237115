import { Options } from "vue-class-component";
import components from "../../common/process/NodesConfigExport";
import BaseFunction from "@/mixins/BaseFunction";

@Options({
    name: "flowable-process-design-node-config",
    components: components,
})
export default class NodeConfig extends BaseFunction {
    private active = "properties";
    get setup(): any {
        return this.$store.getters["flowable/design"];
    }
    set setup(val: any) {
        this.$store.dispatch("flowable/setDesign", val).then();
    }
    get selectedNode() {
        return this.$store.getters["flowable/selectedNode"];
    }
    set selectedNode(val) {
        this.$store.dispatch("flowable/setSelectedNode").then();
    }
    get formConfig() {
        return this.setup.formItems;
    }
    set formConfig(val: any) {
        this.setup.formItems = val;
    }
    get name() {
        switch (this.selectedNode.type) {
            case "ROOT":
                return "设置发起人";
            case "APPROVAL":
                return "设置审批人";
            case "CC":
                return "设置抄送人";
            default:
                return null;
        }
    }
}
