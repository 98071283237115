import { Options } from "vue-class-component";
import BaseFunction from "@/mixins/BaseFunction";

@Options({
    name: "flowable-setting-pro-setting",
})
export default class ProSetting extends BaseFunction {
    get setup(): any {
        return this.$store.getters["flowable/design"];
    }
    set setup(val: any) {
        this.$store.dispatch("flowable/setDesign", val).then();
    }
    public validate(): any {
        return [];
    }
}
